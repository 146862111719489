.credit-card-form {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .card-icons {
    display: flex;
    gap: 20px;
    img {
      height: 35px;
    }
  }
  
  .experation {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}