$primary-color: #d83e86;
$secondary-color: #0adedf;
$accent-light-color: #e9899a;
$accent-dark-color: #460146;
$error-color: #d20c19;
$success-color: #05db96;
$info-color: #fdfb76;
$background-color: #000;
$background-color-main: #222;
$primary-text-color: #FFFFFF;
$secondary-text-color: #424242;
$screen-break: 768px;