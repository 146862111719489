.search-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto;
  
  .search-bar {
    margin: 5px;
    display: flex;
    align-items: center;
    .search-icon {
      font-size: 30px;
    }
    .search-input {
      flex-grow: 1;
      padding: 5px;
      font-size: 16px;
      outline: none;
      color: white;
      background: none;
      border: 1px solid transparent;
      box-shadow: none;
    }
  }
}