.hunter-bar {
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-size: cover;
  background-position: center;
  .picture {
    margin: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    aspect-ratio: 1 / 1; 
    background-size: cover;
    background-position: center;
  }
  .stack {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .handle {
      font-size: 5vw;
    }
    .info-row {
      display: flex;
      gap: 20px;
      span {
        display: inline-flex;
        gap: 5px;
        font-size: 3vw;
      }
    }
  }
}
