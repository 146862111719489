@import 'bounty';

@font-face {
  font-family: 'roboto';
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'rowdies';
  src: url('/fonts/Rowdies-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'rowdies';
  src: url('/fonts/Rowdies-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'sora';
  src: url('/fonts/Sora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sora';
  src: url('/fonts/Sora-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  color: $primary-text-color;
  background-color: $background-color;
  font-family: 'sora';
  font-size: 16px;
  background-color: black;
}

h1 {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 30px;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

p {
  color: #E0E0E0;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

a, a:visited, a:hover, a:active {
  color: $primary-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

span.info {
  color: $info-color;
}

span.success {
  color: $success-color;
}

span.error {
  color: $error-color;
}

.main-body {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%;
  background: linear-gradient(to bottom, #241937, #000000);
}

.content-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content-box {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: $background-color-main;
}

.form-page {
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  max-width: 600px;
}

.content-row {
  margin: 0 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.content-stats-row {
  margin: 20px 10px;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}

.button-icon {
  font-size: 24px;
}

@media (max-width: $screen-break) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}