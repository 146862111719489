.status-bar {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  padding: 0 10px;
  background-color: #222222EE;
  color: white;
  display: flex;
  justify-content: flex-end;
  z-index: 900; 
  gap: 15px;
  .metric {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}