@import '../../bounty';

.main-layout {
  display: flex;
  .main-layout-inner {
    background: linear-gradient(to bottom, #241937, #000000);
    margin: 0 auto;
    padding-bottom: 100px;
    border-radius: 10px;
    width: 1200px;
    
    .main-bar {
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    
      .logo {
        color: $primary-color;
        text-decoration: none;
        font-family: 'rowdies';
        font-size: 32px;
      }
    
      .user {
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}


@media (max-width: $screen-break) {
  .main-layout {
    .main-layout-inner {
      margin: 20px 0 0 0;
      border-radius: 0;
    }
  }
}