@import '../../bounty';

.claim-thumb-image {
  aspect-ratio: 1 / 1; 
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  font-size: 16px;
  
  .votes {
    color: $primary-text-color;
    text-decoration: none;
    display: flex;
    gap: 5px;
    margin: 2px;
    margin-top: auto;
    padding: 4px 6px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.claim-thumb-video {
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* Keeps the aspect ratio 1:1 */
    .video-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 1;

      .votes {
        color: $primary-text-color;
        text-decoration: none;
        display: flex;
        gap: 5px;
        margin: 2px;
        margin-top: auto;
        padding: 4px 6px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}


