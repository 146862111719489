.claim-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .claim-box {
    padding: 6px 0;
    border-radius: 6px;
    background-color: purple;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

