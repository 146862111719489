@import '../bounty';

.nav-bar {
  background-color: #222;
  text-align: center;

  .nav-bar-links {
    display: inline-flex;

    .nav-bar-link {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center; 
      color: white;
      width: 150px;
      height: 40px;
      text-decoration: none;
    }

    .nav-bar-link.nav-bar-active {
      color: white;
    }

    .nav-bar-button {
      width: 100%;
      font-size: 5vw;
    }
  }
}

@media (max-width: $screen-break) {
  .nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: #222;
    width: 100%;
    z-index: 900;
  
    .nav-bar-links {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin: 5px 10px 0px 10px;
      padding: 5px 0px;
      width: 100%;
  
      .nav-bar-link {
        width: 100%;
        height: auto;
        text-align: center;
        color: #ccc;
        font-size: 5vw;
        padding: 10px 0;
      }
  
      .nav-bar-link.nav-bar-active {
        color: white;
      }
  
      .nav-bar-button {
        width: 100%;
        font-size: 5vw;
      }
    }
  }
}