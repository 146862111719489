.form-layout {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%;
  background: linear-gradient(to bottom, #241937, #000000);
}

.form-layout.visible {
  opacity: 1;
}