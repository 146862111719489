@import '../bounty';

.setting {
  padding: 15px;
  border-top: 1px solid #FFFFFF10;
  display: flex;
  justify-content: flex-end;
  
  .title {
    margin-right: auto;
  }
}

.setting:hover {
  background-color: $primary-color;
  cursor: pointer;
}
