@import '../bounty';

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  padding: 15px 25px;
  .logo {
    font-family: 'rowdies';
    font-size: 40px;
    text-decoration: none;
    color: $primary-color;
  }
}