
.bounty-bar {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 6px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .details {
    font-size: 12px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}
