@import '../../bounty';

.round-summary {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

@media (max-width: $screen-break) {
  .round-summary {
    grid-template-columns: repeat(3, 1fr);
  }
}
