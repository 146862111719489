@import '../bounty';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

footer {
  width: 100%;
  margin-top: 100px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 15px;
  color: #999;
  background-color: black;
  border-top: 2px solid #444;

  a, a:visited, a:hover, a:active {
    color: #ccc;
  }

  .footer-questions {
    margin-bottom: 20px;
    font-size: 15px;
  }

  .footer-links {
    display: flex;
    gap: 10px;
    font-size: 15px;
  }

  .footer-socials {
    display: flex;
    gap: 25px;
    font-size: 30px;
  }

  .footer-copyright {
    display: flex;
    gap: 10px;
    font-size: 10px;
  }
}

@media (max-width: $screen-break) {
}