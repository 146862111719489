.bounty-feed {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .feed-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 0;
    border-radius: 6px;
    background-color: purple;
  }
}