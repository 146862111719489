.claim-vote-bar {
  display: flex;
  justify-content: space-around;
  gap: 8px;
  margin: 5px;
  .vote {
    font-size: 30px;
  }
  .selected {
    color: green;
  }
  .disabled {
    color: gray;
  }
  .active {
    color: white;
  }
}