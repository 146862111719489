@import '../../bounty';

.fab-post-claim {
  position: fixed;
  z-index: 1001;
  bottom: 85px;
  left: 15px;
}

.claim-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.profile-row {
  text-decoration: none !important;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  color: white !important;

  .profile-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    aspect-ratio: 1 / 1; 
    background-size: cover;
    background-position: center;
  }

  .profile-stack {
    display: flex;
    gap: 8px;
    width: 100%;
    flex-direction: column;

    .profile-handle {
      font-size: 5vw;
    }
  }
}

.bounty-box {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #222;
}

.post-dialog {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.fund-dialog {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}