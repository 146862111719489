@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page-loading {
  margin: 100px auto 400px auto;
  display: flex;
  justify-content: center;
  animation: fadeIn 2s;
}