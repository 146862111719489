.plan-picker {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .plan {
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    gap: 5px;
    padding: 10px 10px 30px 10px;
    background-color: darkorchid;
  }
  .selected {
    background-color: purple;
  }
}

.plan-info {
  display: flex;
  justify-content: space-between;
}